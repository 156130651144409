import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useImperativeQueryWithTracing from '@aurora/shared-client/components/useImperativeQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import type {
  EndUserRouteAndParams,
  MessageParamPages,
  MessageReplyPageParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { ImageAssociationType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { MessageIdFragment } from '@aurora/shared-generated/types/graphql-types';
import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import brightcovePlayerLoader from '@brightcove/player-loader';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import highlightCodeSamples from '../../../helpers/content/ContentHelper';
import { addTableWrapperHelper } from '@aurora/shared-client/helpers/editor/EditorHelper';
import { isMediaWrapperElement } from '@aurora/shared-client/helpers/editor/EditorMediaHelper';
import type { MentionPagesAndParams } from '@aurora/shared-client/helpers/editor/EditorMentionsHelper';
import {
  getRouteAndParameters,
  isMentionsElement,
  setHrefForMentionsElement
} from '@aurora/shared-client/helpers/editor/EditorMentionsHelper';
import {
  handleSpoilerClick,
  isSpoilerElement
} from '@aurora/shared-client/helpers/editor/EditorSpoilerHelper';
import {
  UPLOAD_STATUS,
  VIDEO_PROCESSING_INDICATOR
} from '@aurora/shared-client/helpers/editor/EditorVideoHelper';
import { removeExtraSpaces } from '../../../helpers/messages/MessageHelper/MessageHelper';
import type { TextSelectedItem } from '@aurora/shared-client/helpers/ui/GlobalState';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import type {
  AssociatedImagesFragment,
  ContextMessageBodyFragment,
  LookupVideoQuery,
  LookupVideoQueryVariables,
  MessageBodyFragment,
  MessageOriginalBodyQuery,
  MessageOriginalBodyQueryVariables
} from '../../../types/graphql-types';
import {
  addConsentButtonClickAction,
  getVideoConsentBannerElement
} from '@aurora/shared-client/helpers/cookie/CookieConsentBannerHelper';
import { getExternalVideoConsentCookie } from '@aurora/shared-client/helpers/cookie/CookieHelper';
import useContextObjectFromPath, {
  useContextObjectDataHelper
} from '../../context/useContextObjectFromPath';
import lookupVideoQuery from '@aurora/shared-client/components/form/RichTextEditorField/LookupVideo.query.graphql';
import ImageOption, { getImageIdFromUrl } from '../../images/ImagePreviewModal/ImageOption';
import useTranslation from '../../useTranslation';
import type { MessageBodyLinkHoverCardsProps } from '../MessageBodyLinkHoverCards/MessageBodyLinkHoverCards';
import MessagePreviewImage from '../MessagePreviewImage/MessagePreviewImage';
import localStyles from './MessageBody.module.css';
import messageOriginalBodyQuery from './MessageOriginalBody.query.graphql';
import { replaceGistScripts } from '../../../helpers/content/GistHelper';
import { getVideoUrlFromIframe } from '@aurora/shared-client/helpers/video/VideoPreviewHelper';

const MessageBodyLinkHoverCards = dynamic<MessageBodyLinkHoverCardsProps>(
  () => import('../MessageBodyLinkHoverCards/MessageBodyLinkHoverCards'),
  { ssr: false }
);

const ImagePreviewModal = dynamic(
  () => import('../../images/ImagePreviewModal/ImagePreviewModal'),
  {
    ssr: false
  }
);

const log = getLog(module);

type MessagePageParams = MessageParamPages | MessageReplyPageParams;
type TimerIndex = {
  [key: string]: NodeJS.Timeout;
};
interface Props {
  /**
   * The message to display the body for.
   */
  message: MessageIdFragment & MessageBodyFragment & ContextMessageBodyFragment;
  /**
   * Whether to trim(remove nbsp's) from the message body or not.
   */
  trimBody?: boolean;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * The number of lines to clamp.
   */
  clampLines?: ClampLinesType;
  /**
   * Whether the `message.searchSnippet` property should be used for displaying the body,
   * otherwise the `message.body` is used.
   */
  useSearchSnippet?: boolean;
  /**
   * Whether to use truncated message or not.
   */
  usePreview?: boolean;
  /**
   * the message body to be highlighted (To be only used for displaying search results)
   */
  bodySearchSnippet?: string;
}

/**
 * Helper to check if selected Item is contained by given element.
 * @param element.
 * @param textSelectedItem - selection Item to be checked.
 */
function isSelectionContainedByElement(
  element: HTMLElement,
  textSelectedItem: TextSelectedItem
): boolean {
  if (!textSelectedItem) {
    return false;
  }
  const { focusElement, anchorElement } = textSelectedItem;
  return element.contains(focusElement) && element.contains(anchorElement);
}

/**
 * Get the `ImageOption` array from the message's body associated images
 *
 * @param AssociatedImagesFragment `message`'s `AssociatedImagesFragment` data
 */
function getImageOptions(associatedImagesFragment: AssociatedImagesFragment): ImageOption[] {
  return associatedImagesFragment?.images?.edges
    .filter(imageEdge => imageEdge.node.associationType === ImageAssociationType.Body)
    .map(imageEdge => new ImageOption(imageEdge.node));
}

/**
 * Message body.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const MessageBody: React.FC<React.PropsWithChildren<Props>> = ({
  trimBody = false,
  className,
  as: Component = 'div',
  message,
  clampLines = false,
  useSearchSnippet = true,
  usePreview = false,
  bodySearchSnippet
}: Props) => {
  const cx = useClassNameMapper(localStyles);
  const { router } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.MESSAGE_BODY);
  const {
    body,
    board: { conversationStyle },
    searchSnippet
  } = message;
  const html = trimBody
    ? removeExtraSpaces(useSearchSnippet ? bodySearchSnippet ?? searchSnippet ?? body : body)
    : useSearchSnippet
    ? bodySearchSnippet ?? searchSnippet ?? body
    : body;
  const isBodyTruncated = Number(message?.bodyLength) > 200;
  const showMessagePreview = usePreview && isBodyTruncated;
  const clampBodyLines = clampLines && isBodyTruncated ? clampLines : false;
  const lookupVideoFetch = useImperativeQueryWithTracing<
    LookupVideoQuery,
    LookupVideoQueryVariables
  >(module, lookupVideoQuery);

  const lookupOriginalBodyFetch = useImperativeQueryWithTracing<
    MessageOriginalBodyQuery,
    MessageOriginalBodyQueryVariables
  >(module, messageOriginalBodyQuery, {
    variables: {
      id: message?.id,
      constraints: {
        revisionNum: {
          eq: message?.revisionNum
        }
      }
    }
  });

  const [showPreview, setShowPreview] = useState(showMessagePreview);
  const [messageBodyClampLines, setMessageBodyClampLines] =
    useState<ClampLinesType>(clampBodyLines);
  const [messageBody, setMessageBody] = useState(html);
  const messageBodyReference = useRef<HTMLElement>(null);
  const [textSelectedItem] = useGlobalState(GlobalStateType.TEXT_SELECTED_ITEM);
  const [showQuoteButton] = useGlobalState(GlobalStateType.SHOW_QUOTE_FOR_SELECTION);
  const blockQuoteTitleClass = cx('lia-blockquote-author-title');
  const blockQuoteAuthorTextClass = cx('lia-blockquote-author-text');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const selectedImage = useRef<ImageOption>(null);
  const imageOptionsRef = useRef<ImageOption[]>(
    getImageOptions(message as AssociatedImagesFragment)
  );
  const { addToast } = useToasts();
  const { getContextObject } = useContextObjectFromPath(module);
  const { getObjectDataForPath } = useContextObjectDataHelper();

  useEffect(() => {
    if (isSelectionContainedByElement(messageBodyReference.current, textSelectedItem)) {
      let blockQuoteContent = document.getSelection().toString();
      if (window.tinymce?.activeEditor) {
        blockQuoteContent = window.tinymce.DOM.encode(blockQuoteContent).replaceAll('\n', '<br>');
      }
      textSelectedItem.callback(
        `<blockquote>
          <span class=${blockQuoteTitleClass}>${message?.author?.login}</span>
          <span class=${blockQuoteAuthorTextClass}>wrote:</span>
          <p>${blockQuoteContent}</p>
        </blockquote>
        <p>&nbsp;</p>`
      );
    }
  }, [textSelectedItem, blockQuoteTitleClass, blockQuoteAuthorTextClass, message?.author?.login]);

  useEffect(() => {
    if (isSelectionContainedByElement(messageBodyReference.current, showQuoteButton)) {
      showQuoteButton.callback(true);
    }
  }, [showQuoteButton]);

  useEffect(() => {
    /** The ? was added to fix a failing story. This may be indicative of another issue. TODO  */
    if (html?.length > 0) {
      setMessageBody(html);
    }
  }, [html]);

  useEffect(() => {
    setShowPreview(showMessagePreview);
  }, [showMessagePreview, usePreview]);

  /**
   * Opens `ImagePreviewModal` on click of an image element in the message body.
   *
   * @param event mouse event
   * @param wrapperElement Image element
   */
  function handleMediaWrapperClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    wrapperElement: HTMLElement
  ) {
    event.preventDefault();
    const imageId = getImageIdFromUrl(wrapperElement.querySelector('img').getAttribute('src'));
    selectedImage.current = imageOptionsRef.current?.find(option => option.id === imageId);
    setShowPreviewModal(true);
  }

  /**
   * Navigate to the respective page based on the mention clicked.
   *
   * @param event the mouse event.
   * @param mentionElement the mention element.
   */
  const handleMentionClick = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>, mentionElement: HTMLElement) => {
      if (event.metaKey || event.ctrlKey) {
        return;
      }
      const url = mentionElement.getAttribute('href');
      event.preventDefault();
      const routeAndParams = getRouteAndParameters(mentionElement);
      if (routeAndParams) {
        const { route, params } = routeAndParams;
        const mentionsType = getObjectDataForPath(url);
        const updatedData = await getContextObject(url);
        if (updatedData !== null) {
          const finalParams =
            params &&
            Object.fromEntries(
              Object.keys(params).map(key => [key, getCaseSensitivePath(params[key])])
            );
          await router.pushRoute<MentionPagesAndParams>(
            route,
            finalParams as unknown as MessagePageParams
          );
        } else {
          const toastProps: ToastProps = {
            toastVariant: ToastVariant.FLYOUT,
            alertVariant: ToastAlertVariant.DANGER,
            title: formatMessage('mentionsErrorTitle', { mentionsType: mentionsType.urlObject }),
            message: formatMessage('mentionsErrorMessage', {
              mentionsType: mentionsType.urlObject
            }),
            autohide: true,
            id: `Mentions-${mentionsType.entityId}-failure`
          };
          addToast(toastProps);
        }
      }
    },
    [getCaseSensitivePath, getObjectDataForPath, addToast, formatMessage, getContextObject, router]
  );

  /**
   * Navigates to the respective page when internal url is clicked.
   *
   * @param event the mouse event.
   * @param linkElement the link element.
   */
  const handleInternalLinkClick = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>, linkElement: HTMLElement) => {
      const { href } = linkElement as HTMLLinkElement;
      event.preventDefault();
      const { route, params } = router.getRouteAndParamsByPath(href);
      const finalParams =
        params &&
        Object.fromEntries(
          Object.keys(params).map(key => [key, getCaseSensitivePath(params[key])])
        );
      await router.pushRoute<EndUserRouteAndParams<EndUserPages>>(route, finalParams);
    },
    [router, getCaseSensitivePath]
  );

  /**
   * Handles the click event on the body of the message
   *
   * @param event Mouse Event
   */
  const handleBodyClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLElement;

      if (isMentionsElement(target)) {
        handleMentionClick(event, target);
      }

      if (target.classList.contains('lia-internal-link')) {
        handleInternalLinkClick(event, target);
      }

      if (isMediaWrapperElement(target)) {
        handleMediaWrapperClick(event, target);
      }

      if (isSpoilerElement(target)) {
        handleSpoilerClick(event, target);
      }
    },
    [handleInternalLinkClick, handleMentionClick]
  );

  useEffect(() => {
    const messageBodyElement = messageBodyReference.current;

    if (messageBodyElement) {
      highlightCodeSamples(messageBodyElement, cx('border'));

      messageBodyElement.querySelectorAll<HTMLElement>('.lia-mention').forEach(mentionElement => {
        const mentionElementTitle = mentionElement?.dataset?.liaMessageTitle;
        if (mentionElementTitle?.startsWith('Re:')) {
          setHrefForMentionsElement(
            mentionElement,
            router,
            getCaseSensitivePath,
            false,
            mentionElement.dataset?.liaMessageTopicId,
            mentionElement.dataset?.liaMessageTopicSubject
          );
        } else {
          setHrefForMentionsElement(mentionElement, router, getCaseSensitivePath, false);
        }
      });

      messageBodyElement
        .querySelectorAll<HTMLElement>('.lia-internal-link')
        .forEach(linkElement => {
          const href = linkElement.getAttribute('href');
          linkElement.setAttribute('href', getCaseSensitivePath(href));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageBody, messageBodyReference.current]);

  /**
   * Adds the brightcove player loader.
   * @param videoElement video container element.
   * @param referenceNode the data media node, which gets replaced by brightcove player loaders.
   * @param parentReferenceNode the parent div node of reference node.
   * @param referencedExternalId the remove video reference id.
   * @param accountId brightcove account id.
   */
  async function addBrightCovePlayer(
    videoElement: HTMLElement,
    referenceNode: HTMLImageElement,
    parentReferenceNode: HTMLElement,
    referencedExternalId: string,
    accountId: string
  ) {
    videoElement.dataset.liaVideoStatus = UPLOAD_STATUS.LIVE;
    const { width, height } = referenceNode;
    parentReferenceNode.style.width = `${width}px`;
    parentReferenceNode.style.height = `${height}px`;

    videoElement.querySelector('.lia-video-player-icon')?.remove();
    videoElement.querySelector('.lia-video-overlay')?.remove();
    videoElement.querySelector('.' + VIDEO_PROCESSING_INDICATOR)?.remove();
    // add the Brightcove Video Player
    await brightcovePlayerLoader({
      refNode: referenceNode,
      refNodeInsert: 'replace',
      accountId,
      playerId: 'default',
      videoId: referencedExternalId,
      onFailure(error) {
        log.error('BrightCove Player Error:', error);
      }
    });
  }

  /**
   * Triggers the lookup video and adds the brightcove player if the video is live.
   * @param videoElement video container element.
   * @param parentReferenceNode the parent div node of data media node.
   * @param referencedExternalId the remove video reference id.
   * @param accountId brightcove account id.
   */
  async function videoLookupCall(
    videoElement: HTMLElement,
    parentReferenceNode: HTMLElement,
    referencedExternalId: string,
    accountId: string
  ): Promise<boolean> {
    const lookupVideoResult = await lookupVideoFetch({ videoId: referencedExternalId });
    const lookupVideo = lookupVideoResult?.data?.lookupVideo;
    const uploadStatus = lookupVideo?.uploadStatus;
    const referenceNode = parentReferenceNode.querySelector('img');
    const thumbnail = lookupVideo?.thumbnailUrl;
    if (uploadStatus.toLowerCase() === UPLOAD_STATUS.LIVE && referenceNode !== null && thumbnail) {
      await addBrightCovePlayer(
        videoElement,
        referenceNode,
        parentReferenceNode,
        referencedExternalId,
        accountId
      );
    }
    return uploadStatus.toLowerCase() === UPLOAD_STATUS.LIVE && thumbnail !== null;
  }

  async function handleViewMoreClick() {
    const lookupOriginalBodyResult = await lookupOriginalBodyFetch();
    setMessageBodyClampLines(false);
    setShowPreview(false);
    setMessageBody(lookupOriginalBodyResult.data.message.revisions.edges[0].node.originalBody);
  }

  const lookupVideoTimer = useRef<TimerIndex>({});
  const {
    publicConfig: { showExternalVideoCookieBanner }
  } = useContext(TenantContext);
  const externalVideoConsentProvided = getExternalVideoConsentCookie();
  const showBanner = showExternalVideoCookieBanner && !externalVideoConsentProvided;
  const videoElementsMap = useRef(new Map<string, HTMLElement>());

  useEffect(() => {
    const messageBodyElement = messageBodyReference.current;
    if (messageBodyElement) {
      const videoElements: NodeListOf<HTMLElement> =
        messageBodyElement.querySelectorAll('.lia-video-container');
      videoElements.forEach(videoElement => {
        const iframe = videoElement.querySelector('iframe');
        if (iframe && showBanner) {
          videoElementsMap.current.set(videoElement.dataset.videoId, videoElement);
          const videoLink = `<a href="${getVideoUrlFromIframe(iframe?.src)}"
            class="${cx('lia-external-url')}"
            target="_blank" rel="noreferrer noopener">${formatMessage('urlText')}</a>`;

          const bannerElement = getVideoConsentBannerElement(
            cx,
            videoElement,
            iframe?.src,
            formatMessage('bannerTitle', { url: videoLink }),
            formatMessage('buttonTitle')
          );
          videoElement.replaceWith(bannerElement);
        } else {
          const referencedExternalId = videoElement.dataset.videoRemoteVid;
          const accountId = videoElement.dataset.account;
          const parentReferenceNode = videoElement?.querySelector(
            '.lia-video-display-wrapper'
          ) as HTMLElement;
          const isBrightCovePlayerLoaded = parentReferenceNode?.querySelector('video-js') !== null;

          if (isBrightCovePlayerLoaded) {
            const referenceNode = parentReferenceNode?.querySelector('video-js');
            videoElement.dataset.liaVideoStatus = UPLOAD_STATUS.LIVE;
            if (referenceNode) {
              brightcovePlayerLoader({
                refNode: referenceNode,
                refNodeInsert: 'replace',
                accountId,
                playerId: 'default'
              })
                .then(success => {
                  const player = success.ref;
                  // Adding custom error for the video player
                  player.errors({
                    errors: {
                      'video-processing-error': {
                        headline: formatMessage('videoProcessing'),
                        dismiss: false
                      }
                    }
                  });
                  player.catalog.getVideo(referencedExternalId, (error, video) => {
                    if (error) {
                      player.error({ code: 'video-processing-error' });
                    } else {
                      player.catalog.load(video);
                    }
                  });
                  return true;
                })
                .catch(error => {
                  log.error('Brightcover Player Error:', error);
                  throw new Error(error);
                });
            }
          } else if (parentReferenceNode !== null && !isBrightCovePlayerLoaded) {
            //initial video lookup call at t = 0 secs. After that lookup video timer is executed at every 15 secs.
            let isBrightcoveAdded = false;
            videoLookupCall(videoElement, parentReferenceNode, referencedExternalId, accountId)
              .then(value => {
                isBrightcoveAdded = value;
                if (!isBrightcoveAdded) {
                  lookupVideoTimer.current[referencedExternalId] = setInterval(async () => {
                    try {
                      isBrightcoveAdded = await videoLookupCall(
                        videoElement,
                        parentReferenceNode,
                        referencedExternalId,
                        accountId
                      );
                      if (isBrightcoveAdded) {
                        // stop polling once the video is live
                        clearInterval(lookupVideoTimer.current[referencedExternalId]);
                      }
                    } catch (error) {
                      log.error(error, 'An error occurred while loading the video');
                    }
                  }, 15000);
                }
                return isBrightcoveAdded;
              })
              .catch(error => {
                log.error(error, 'An error occurred while loading the video');
              });
          }
        }
      });
      //Adding wrapper around existing tables for responsiveness
      messageBodyElement.querySelectorAll('table').forEach(element => {
        addTableWrapperHelper(element, cx);
      });
    }
    return () => {
      for (const key in lookupVideoTimer.current) {
        if (lookupVideoTimer.current.hasOwnProperty(key)) {
          clearInterval(lookupVideoTimer.current[key]);
        }
      }
      lookupVideoTimer.current = {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showBanner) {
    addConsentButtonClickAction(messageBodyReference, cx, videoElementsMap.current);
  }
  /**
   * When the current and destination route doesn't match,
   * it removes the brigtcoveplayer loader objects
   */
  const clearBrightCovePlayer = useCallback(
    url => {
      const { route: currentRoute, params: currentRouteParams } = router.getRouteAndParamsByPath(
        router.asPath
      );
      if (url) {
        const { route: destinationRoute, params: destinationRouteParams } =
          router.getRouteAndParamsByPath(url);

        if (
          currentRoute !== destinationRoute ||
          !isEqual({ ...currentRouteParams }, { ...destinationRouteParams })
        ) {
          // Removes all the dangling brightcove objects
          brightcovePlayerLoader.reset();
        }
      }
    },
    [router]
  );

  useEffect(() => {
    router.events.on('routeChangeStart', clearBrightCovePlayer);
    return () => {
      router.events.off('routeChangeStart', clearBrightCovePlayer);
    };
  });

  useEffect(() => {
    setMessageBodyClampLines(clampLines);
  }, [clampLines]);

  if (textLoading) {
    return null;
  }

  function renderPreviewImage(): React.ReactElement {
    return (
      <MessagePreviewImage
        className={cx('lia-preview-img')}
        message={message}
        associationRanks={[ImageAssociationType.Body]}
      />
    );
  }

  function renderViewMore(): React.ReactElement {
    return (
      <Button
        variant={ButtonVariant.LINK}
        className={cx('lia-g-loader-btn lia-show-more')}
        onClick={handleViewMoreClick}
      >
        <Icon icon={Icons.ChevronDownIcon} className={cx('lia-g-mr-5')} size={IconSize.PX_16} />
        {formatMessage('showMessageBody')}
      </Button>
    );
  }

  const processedContent = messageBody ? { __html: replaceGistScripts(messageBody) } : null;

  return (
    <>
      <Component
        onClick={handleBodyClick}
        className={cx(
          `lia-g-message-body lia-g-message-body-${conversationStyle?.toLowerCase()} clearfix`,
          { [`lia-g-clamp lia-g-clamp-${messageBodyClampLines}`]: messageBodyClampLines },
          className
        )}
        dangerouslySetInnerHTML={processedContent}
        ref={messageBodyReference}
      />
      <MessageBodyLinkHoverCards elementRef={messageBodyReference} />
      {showPreview ? (
        <>
          {renderPreviewImage()}
          {renderViewMore()}
        </>
      ) : (
        showPreviewModal && (
          <ImagePreviewModal
            imageOptions={imageOptionsRef.current}
            selectedImage={selectedImage.current}
            show={showPreviewModal}
            onHide={() => {
              selectedImage.current = null;
              setShowPreviewModal(false);
            }}
          />
        )
      )}
    </>
  );
};

export default MessageBody;
